export default {
  register: {
    id: 'EMAIL_REGISTER_AN_EMAIL',
    defaultMessage: 'Register an Email',
    description:
      'Used on the modal to tell the user where they can register their email',
  },
  pleaseEnterEmail: {
    id: 'EMAIL_PLEASE_ENTER_EMAIL',
    defaultMessage:
      'Please enter the email address you would like to verify in the field below.',
    description:
      'Used on the modal to tell the user where they can register their email within the input field',
  },
  sendVerificationEmail: {
    id: 'EMAIL_SEND_VERIFICATION_EMAIL',
    defaultMessage: 'Send Verification Email',
    description:
      'A button for the user to press so they can send their verified email',
  },
  sentEmailVerification: {
    id: 'EMAIL_SENT_VERIFICATION_EMAIL',
    defaultMessage:
      "We've sent an email to {EMAIL_ADDRESS}. Please click the link within the email to finish verifying your email address",
    description:
      'Used to notify the user their verified email registration is complete',
  },
  closeVerificationEmail: {
    id: 'EMAIL_CLOSE_VERIFICATION_EMAIL_MODAL',
    defaultMessage: 'Close',
    description: 'A button for the user to press that will cancel the modal',
  },
  placeHolderVerificationEmail: {
    id: 'EMAIL_PLACEHOLDER_VERIFICATION_EMAIL',
    defaultMessage: 'example@domain.com',
    description: 'A placeholder for the email verification input modal',
  },
  blockedEmailVerificationEmail: {
    id: 'EMAIL_VERIFICATION_MODAL_BLOCKED_EMAIL',
    defaultMessage: 'This email is blocked',
    description: 'Text to inform the user their email is blocked',
  },
  alreadyVerifiedOrIntegratedEmail: {
    id:
      'EMAIL_ANNOUNCEMENTS_VERIFICATION_MODAL_ALREADY_VERIFIED_OR_INTEGRATED_EMAIL',
    defaultMessage:
      "You're all set! This email address is already verified or integrated.",
    description:
      'Text to inform the user their email has already been verified or integrated',
  },
  verifyYourEmail: {
    id: 'EMAIL_VERIFY',
    defaultMessage: 'Verify Your Email',
    description:
      'Used on the modal to tell the user where they can verify their email',
  },
  verifyYourEmailDescription: {
    id: 'EMAIL_VERIFY_DESCRIPTION',
    defaultMessage:
      'Please enter the email address you would like to verify in the field below.' +
      '{LINE_BREAK}{LINE_BREAK}' +
      'After verifying your email address, you will be required to set up domain authentication before an email address can be used. ' +
      'Domain authentication can only be set up for email domains that you own or have access to alter DNS records for. ' +
      'Please keep in mind that public domain email addresses (@gmail.com, @yahoo.com, etc.) can only be authenticated by the companies that own those domains, ' +
      'and therefore cannot be used by most people.' +
      '{LINE_BREAK}{LINE_BREAK}' +
      'For more information on Domain Authentication and why this requirement exists, please visit <b>https://www.cision.com/legal/email-security/</b>.' +
      '{LINE_BREAK}{LINE_BREAK}',
    description:
      'Used on the modal to tell the user where they can verify their email within the input field',
  },
  notVerifiedEmail: {
    id: 'EMAIL_ANNOUNCEMENTS_VERIFICATION_MODAL_PENDING_VERIFICATION',
    defaultMessage: 'Email verification is in pending state.',
    description:
      'Text to inform the user their email is in Pending state to be confirmed',
  },
};
