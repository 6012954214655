import React, { useMemo, useState } from 'react';

import { Dropdown, Loader } from '@cision/rover-ui';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { SvgIcon } from '@trendkite/ui';
import { DEV_FEATURES, OUTREACH_EMAIL_ACCOUNT_TYPE } from 'constants/constants';
import messages from 'pages/Email/components/EmailCampaignComposer/EmailCampaignComposer.messages';
import EmailCampaignComposerAddVerifiedEmailModal from 'pages/Email/components/EmailCampaignComposer/EmailCampaignComposerAddVerifiedEmailModal';
import {
  emailVerifiedEmailsLoadingSelector,
  emailVerifiedEmailsSelector,
  EmailSelectorArgs,
} from 'pages/Email/Email-selector';
import { userIdSelector } from 'selectors/account';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import 'pages/Email/components/EmailCampaignComposer/EmailCampaignComposer.scss';

interface Email {
  id: number;
  emailAddress: string;
  verified: boolean;
  type: string;
  domainAuthorized: boolean;
  user: { id: number };
}

interface EmailDropdownMenuProps {
  emails: Email[];
  handleVerifiedEmail: (email: Email) => void;
  opened: boolean;
  handleToggle: () => void;
}

const EmailDropdownMenu: React.FC<EmailDropdownMenuProps> = ({
  emails = [],
  handleVerifiedEmail,
  opened,
  handleToggle,
}) => {
  const intl = useIntl();
  const baseClass = 'email-dropdown-menu';

  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(
    false,
  );
  const [
    verifiedOrIntegratedEmailValidation,
    setVerifiedOrIntegratedEmailValidation,
  ] = useState(false);
  const [notVerifiedEmail, setNotVerifiedEmail] = useState(false);
  const [hasLongWidth, setHasLongWidth] = useState(false);

  const hasSendGridPitchFF = userHasDevFeatureFlag(DEV_FEATURES.sendgridPitch);
  const hasDkimSignedDevFF = userHasDevFeatureFlag(
    DEV_FEATURES.enableDkimSignedStatus,
  );
  const hasMockDataDevFF = userHasDevFeatureFlag(DEV_FEATURES.jortsMockData);

  const verifiedEmailsLoading = useSelector(emailVerifiedEmailsLoadingSelector);
  const verifiedEmails: EmailSelectorArgs = useSelector(
    emailVerifiedEmailsSelector,
  );
  const currentUserId = useSelector(userIdSelector);

  const resetVerifiedOrIntegratedEmailValidation = () => {
    setVerifiedOrIntegratedEmailValidation(false);
    setNotVerifiedEmail(false);
  };

  const toggleEmailVerificationModal = () => {
    setShowEmailVerificationModal(!showEmailVerificationModal);
    resetVerifiedOrIntegratedEmailValidation();
  };

  const sortedEmails = useMemo(() => {
    const sortByEmailAddress = (emailAccounts: Email[]) =>
      emailAccounts.sort((a, b) =>
        a.emailAddress.localeCompare(b.emailAddress),
      );

    if (!hasDkimSignedDevFF) {
      return emails.sort((a, b) => a.id - b.id);
    }

    const verifiedAndAuthorizedEmails = sortByEmailAddress(
      emails.filter(
        email =>
          ((email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.integration ||
            (email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification &&
              email.verified)) &&
            email.domainAuthorized) ||
          (email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.username &&
            email.domainAuthorized),
      ),
    );

    const verificationPendingEmails = sortByEmailAddress(
      emails.filter(
        email =>
          email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification &&
          !email.verified,
      ),
    );

    const dkimPendingEmails = sortByEmailAddress(
      emails.filter(
        email =>
          ((email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.integration ||
            (email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification &&
              email.verified)) &&
            !email.domainAuthorized) ||
          (email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.username &&
            !email.domainAuthorized),
      ),
    );

    setHasLongWidth(
      verificationPendingEmails.length > 0 || dkimPendingEmails.length > 0,
    );

    return [
      ...verifiedAndAuthorizedEmails,
      ...verificationPendingEmails,
      ...dkimPendingEmails,
    ];
  }, [emails, hasDkimSignedDevFF]);

  const additionalClasses = hasLongWidth
    ? `${baseClass}__adjusted-from-dropdown-menu`
    : '';

  const renderDropdown = () => (
    <Dropdown
      isOpen={opened}
      onToggle={handleToggle}
      className={`${baseClass}__from-dropdown`}
    >
      <Dropdown.Menu
        className={`t-bg-white t-p-10 t-min-w-0 t-w-480 ${baseClass}__from-dropdown-menu ${additionalClasses}`}
      >
        {verifiedEmailsLoading ? (
          <Loader size="sm" style={{ margin: '15px' }} />
        ) : (
          <div className={`${baseClass}__from-dropdown-email-container`}>
            {sortedEmails.map(email => {
              const {
                id,
                verified,
                type,
                domainAuthorized,
                emailAddress,
              } = email;
              const notVerified =
                !verified && type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification;
              const notDomainAuthenticated =
                hasDkimSignedDevFF &&
                !domainAuthorized &&
                (type === OUTREACH_EMAIL_ACCOUNT_TYPE.integration ||
                  type === OUTREACH_EMAIL_ACCOUNT_TYPE.username ||
                  (type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification &&
                    verified));

              const additionalClasses =
                notVerified || notDomainAuthenticated
                  ? `${baseClass}__notVerified`
                  : '';

              return (
                <Dropdown.Menu.Item
                  data-qa="X9OxHgqAsHHYWwuE0yEws"
                  className={`${baseClass}__from-dropdown-menu-item ${baseClass}__display-email ${additionalClasses}`}
                  onClick={() => handleVerifiedEmail(email)}
                  key={id}
                >
                  <p className="emailAddress">
                    {hasSendGridPitchFF ? emailAddress : `<${emailAddress}>`}
                  </p>
                  {notVerified && (
                    <p>
                      {intl.formatMessage(
                        hasDkimSignedDevFF
                          ? messages.emailComposerPendingVerificationDKIM
                          : messages.emailComposerPendingVerification,
                      )}
                    </p>
                  )}
                  {notDomainAuthenticated && (
                    <p>
                      {intl.formatMessage(
                        messages.emailComposerPendingDomainAuthentication,
                      )}
                    </p>
                  )}
                </Dropdown.Menu.Item>
              );
            })}
          </div>
        )}
        <button
          data-qa="4lKu8MQna21WFxITnl1AV5e"
          onClick={() => toggleEmailVerificationModal()}
          className={`${baseClass}__from-dropdown-add-email`}
        >
          <SvgIcon icon="plus" width={15} height={15} />
          {intl.formatMessage(messages.emailComposerAddAndVerify)}
        </button>
      </Dropdown.Menu>
    </Dropdown>
  );

  const checkVerifiedOrIntegratedEmail = (checkEmail: string) => {
    const verifiedEmailAccounts = verifiedEmails.emailAccounts || [];

    const { yourIntegrations, notVerified } = verifiedEmailAccounts.reduce(
      (
        acc: { yourIntegrations: Email[]; notVerified: Email[] },
        email: Email,
      ) => {
        const isIntegration =
          email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.integration;
        const isVerification =
          email.type === OUTREACH_EMAIL_ACCOUNT_TYPE.verification;
        const isOwnedByUser = email.user.id === currentUserId;

        if (
          (isIntegration || (isVerification && email.verified)) &&
          isOwnedByUser
        ) {
          acc.yourIntegrations.push(email);
        }

        if (!email.verified && isVerification) {
          acc.notVerified.push(email);
        }

        return acc;
      },
      { yourIntegrations: [], notVerified: [] },
    );

    const isNotVerified = notVerified.some(
      integration => integration.emailAddress === checkEmail,
    );
    const isVerifiedOrIntegrated = yourIntegrations.some(
      integration => integration.emailAddress === checkEmail,
    );

    setNotVerifiedEmail(isNotVerified);
    setVerifiedOrIntegratedEmailValidation(isVerifiedOrIntegrated);
  };

  const renderEmailVerificationModal = () => {
    if (!showEmailVerificationModal) {
      return null;
    }

    return (
      <EmailCampaignComposerAddVerifiedEmailModal
        isOpen={showEmailVerificationModal}
        toggle={toggleEmailVerificationModal}
        hasMockDataDevFF={hasMockDataDevFF}
        checkVerifiedOrIntegratedEmail={checkVerifiedOrIntegratedEmail}
        verifiedOrIntegratedEmailValidation={
          verifiedOrIntegratedEmailValidation
        }
        resetVerifiedOrIntegratedEmailValidation={
          resetVerifiedOrIntegratedEmailValidation
        }
        notVerifiedEmail={notVerifiedEmail}
      />
    );
  };

  return (
    <>
      {renderDropdown()}
      {renderEmailVerificationModal()}
    </>
  );
};

export default EmailDropdownMenu;
