import moment from 'moment';

import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';

import { DATE_RANGE_COMPARE_TYPES } from 'components/widget/constants';
import {
  getDateRange,
  getWidgetV3CompareDateRange,
  buildDateRangeParams,
  buildTransientWidgetRequest,
  buildSocialTransientWidgetData,
  getWidgetTypeFromMetrics,
  getChartTypeFromMetrics,
  isSocialSearch,
  isAggregateReadership,
  isAve,
  hydrateWidgetData,
  sortBySourcesId,
} from 'components/widgetV3/utils';
import {
  convertWidgetFormToMetrics,
  convertWidgetFormToV3Widget,
  createDateRangeCompareMetric,
} from 'components/widgetWizard/utils';
import {
  NEW_DASHBOARD_ENDPOINT,
  TILE_ENDPOINT,
  WIDGET_V3_ENDPOINT,
  DASHBOARD_ENDPOINT_V2,
  NEW_REPORT_ENDPOINT,
} from 'constants/apis';
import {
  DEV_FEATURES,
  FEATURES,
  PRIMARY_NAV_HEIGHT,
  SECONDARY_NAV_HEIGHT,
  WIDGET_TYPES,
  WIDGET_TYPE,
  DEFAULT_DATE_FORMAT_INTL,
  WIDGETS_V3_BACKEND_CACHE_RETRY_LIMIT,
  WIDGETS_V3_BACKEND_CACHE_RETRY_INTERVAL,
} from 'constants/constants';
import { campaignAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-campaign-reducer';
import dashboardBulSearchMessages from 'pages/Dashboard/DashboardsBulkSearchModal/DashboardsBulkSearchModal.messages';
import { MessagesStatus } from 'pages/Impact/constants';
import { AnalyticSlideLayout } from 'pages/Report/Slide/SlideLayout';
import { getCampaignsForUser } from 'reducers/campaigns/campaign-list';

import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';

import {
  buildBulkSearchPayloadSelector,
  Dashboard,
  getDashboardWidgetsIds,
  getWidgetsPoweredBySearch,
  shouldWidgetUseBackendCacheSelector,
} from 'selectors/dashboards/dashboard-hub';

import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
} from 'services/feature-service/feature-service';
import restServiceCache from 'services/rest-service/cache';
import {
  performPost,
  performGet,
  performPatch,
  performDelete,
  performPut,
  csrfPerformPost,
} from 'services/rest-service/rest-service';

import {
  buildDateRangeCompareTimestamps,
  DATE_RANGE_KEYS,
  getTimestampsForAnyRange,
  getTimestampsForAnySocialRange,
} from '../../utils/date/date-util';

import messages from '../WidgetDrilldown/DrilldownArticleList/form.messages';

import { getWidgetSearchIdsHash } from './DashboardTable/utils';

export const CREATE_DASHBOARD = 'dashboard/CREATE_DASHBOARD';
export const CREATE_DASHBOARD_SUCCESS = 'dashboard/CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_ERROR = 'dashboard/CREATE_DASHBOARD_ERROR';

export const DUPLICATE_ANALYTIC = 'analytics/DUPLICATE_ANALYTIC';
export const DUPLICATE_ANALYTIC_SUCCESS =
  'analytics/DUPLICATE_ANALYTIC_SUCCESS';
export const DUPLICATE_ANALYTIC_ERROR = 'analytics/DUPLICATE_ANALYTIC_ERROR';

export const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'dashboard/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_ERROR = 'dashboard/GET_DASHBOARD_ERROR';
export const UPDATE_DASHBOARD_ARCHIVE_STATUS =
  'dashboard/UPDATE_DASHBOARD_ARCHIVE_STATUS';

export const CLEAR_ANALYTIC = 'analytics/CLEAR_ANALYTIC';
export const CLEAR_DUPLICATE_ANALYTIC = 'analytics/CLEAR_DUPLICATE_ANALYTIC';

export const UPDATE_LAYOUT = 'dashboard/layout/UPDATE_LAYOUT';
export const UPDATE_LAYOUT_SUCCESS = 'dashboard/layout/UPDATE_LAYOUT_SUCCESS';
export const UPDATE_LAYOUT_ERROR = 'dashboard/layout/UPDATE_LAYOUT_ERROR';

export const CREATE_TILE = 'dashboard/layout/CREATE_TILE';
export const CREATE_V3_TILE_SUCCESS = 'dashboard/layout/CREATE_V3_TILE_SUCCESS';
export const CREATE_TILE_ERROR = 'dashboard/layout/CREATE_TILE_ERROR';

export const CREATE_WIDGET = 'dashbaord/layout/CREATE_WIDGET';
export const CREATE_WIDGET_SUCCESS = 'dashboard/layout/CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_ERROR = 'dashboard/layout/CREATE_WIDGET_ERROR';

export const GET_V3_WIDGET = 'chart-widget/GET_V3_WIDGET';
export const GET_V3_WIDGET_ERROR = 'chart-widget/GET_V3_WIDGET_ERROR';
export const GET_V3_WIDGET_SUCCESS = 'chart-widget/GET_V3_WIDGET_SUCCESS';

export const GET_V3_CHART_DATA = 'chart-widget/GET_V3_CHART_DATA';
export const GET_V3_CHART_DATA_ERROR = 'chart-widget/GET_V3_CHART_DATA_ERROR';
export const GET_V3_CHART_DATA_SUCCESS =
  'chart-widget/GET_V3_CHART_DATA_SUCCESS';

export const UPDATE_V3_WIDGET = 'dashboard/layout/UPDATE_V3_WIDGET';
export const UPDATE_V3_WIDGET_SUCCESS =
  'dashboard/layout/UPDATE_V3_WIDGET_SUCCESS';
export const UPDATE_V3_WIDGET_ERROR = 'dashboard/layout/UPDATE_V3_WIDGET_ERROR';

export const SET_WIDGET_DATERANGE = 'widget/SET_WIDGET_DATERANGE';
export const SET_WIDGET_DATERANGE_ERROR = 'widget/SET_WIDGET_DATE_RANGE_ERROR';
export const SET_WIDGET_DATERANGE_SUCCESS =
  'widget/SET_WIDGET_DATERANGE_SUCCESS';

export const ADD_DASHBOARD_TO_CAMPAIGNS_ERROR =
  'dashboard/ADD_DASHBOARD_TO_CAMPAIGNS_ERROR';

export const DELETE_V3_TILE = 'dashboard/layout/DELETE_V3_TILE';

export const SET_ANALYTIC_SHARING = 'dashboard/SET_ANALYTIC_SHARING';

export const EXPORT_DASHBOARD_TO_CSV = 'dashboard/EXPORT_DASHBOARD_TO_CSV';

export const SET_BULK_SEARCHES_REPLACEMENT =
  'dashboard/SET_BULK_SEARCHES_REPLACEMENT';
export const RESET_BULK_SEARCHES_REPLACEMENT =
  'dashboard/RESET_BULK_SEARCHES_REPLACEMENT';
export const SET_CURRENT_SEARCH_ID = 'dashboard/SET_BULK_SEARCHES_ID';
export const RESET_CURRENT_SEARCH_ID = 'dashboard/RESET_BULK_SEARCHES_ID';
export const SET_BULK_SEARCH_UPDATE = 'dashboard/SET_BULK_SEARCH_UPDATE';

export const SET_DASHBOARD_CSV_EXPORT_TYPE =
  'dashboard/SET_DASHBOARD_CSV_EXPORT_TYPE';
export const CLEAR_WIDGETS_TO_RELOAD = 'dashboard/CLEAR_WIDGETS_TO_RELOAD';
export const SET_WIDGETS_TO_RELOAD = 'dashboard/SET_WIDGETS_TO_RELOAD';
export const DELETE_WIDGET_FROM_RELOAD_LIST =
  'dashboard/DELETE_WIDGET_FROM_RELOAD_LIST';

export const LIST_ANALYTICS_SUCCESS = 'analytics/LIST_ANALYTICS_SUCCESS';

export const SET_SHARED_DASHBOARD = 'dashboard/SET_SHARED_DASHBOARD';

export const CLEAR_WIDGET_DATA = 'dashboard/CLEAR_WIDGET_DATA';

export const SET_DASHBOARDS_LISTS_SIDE_TRAY_OPEN =
  'dashboard/SET_DASHBOARDS_LIST_SIDEBAR_OPEN';

export const RESET_CREATE_DASHBOARD_FIELDS =
  'dashboard/RESET_CREATE_DASHBOARD_FIELDS';

export const IS_DASHBOARD_VIEW_AND_HAS_ARCHIVE_DELETE_DASHBOARD_FF =
  'dashboard/IS_DASHBOARD_VIEW_AND_HAS_ARCHIVE_DELETE_DASHBOARD_FF';

export const UPDATE_SEARCHES_BY_DASHBOARD =
  'dashboard/UPDATE_SEARCHES_BY_DASHBOARD';
export const CLEAR_ALL_DASHBOARD_SEARCHES =
  'dashboard/CLEAR_ALL_DASHBOARD_SEARCHES';

export const WIDGET_SHOULD_USE_BACKEND_CACHE =
  'dashboard/WIDGET_SHOULD_USE_BACKEND_CACHE';

const headerOffset = PRIMARY_NAV_HEIGHT + SECONDARY_NAV_HEIGHT;

export const initialState = {
  duplicateDashboardId: null,
  duplicatingSuccess: false,
  error: false,
  loading: true,
  isDashboardCreated: false,
  errorCreatingDashboard: false,
  dashboard: null,
  isDashboardsListsSideTrayOpen: false,
  shouldWidgetUseBackendCache: false,
  widgetV3ById: {},
  export: {
    initiateExport: false,
    exportedWidgets: [],
    csvExportType: 'CSV',
  },
  layoutError: false,
  updatingAnalytic: false,
  updatingAnalyticError: null,
  creatingTile: false,
  createTileError: null,
  creatingWidget: false,
  createWidgetError: null,
  updatingWidgetError: null,
  widgetErrorMessage: '',
  bulkSearch: {
    updateInProgress: false,
    currentSearch: null,
    newSearches: {},
  },
  widgetsToReload: [],
  isDashboardViewAndHasArchiveDeleteDashboardsFF: false,
  dashboardSearches: {},
};

const dashboardHubReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LIST_ANALYTICS_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case CREATE_DASHBOARD:
      return {
        ...state,
        isDashboardCreated: false,
        errorCreatingDashboard: null,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        loading: true,
        dashboard: null,
        error: null,
      };
    case CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        isDashboardCreated: true,
        errorCreatingDashboard: null,
        dashboard: action.payload.dashboard,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dashboard: action.payload.dashboard,
      };
    case CREATE_DASHBOARD_ERROR:
      return {
        ...state,
        isDashboardCreated: false,
        errorCreatingDashboard: action.payload.error,
      };
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_DASHBOARD_ARCHIVE_STATUS: {
      return {
        ...state,
        dashboard: action.payload.dashboard,
      };
    }
    case SET_BULK_SEARCHES_REPLACEMENT:
      return {
        ...state,
        bulkSearch: {
          ...state.bulkSearch,
          newSearches: {
            ...state.bulkSearch.newSearches,
            [action.payload.currentSearchId]: action.payload.currentSearch,
          },
        },
      };

    case SET_CURRENT_SEARCH_ID:
      return {
        ...state,
        bulkSearch: {
          ...state.bulkSearch,
          currentSearch: action.payload,
        },
      };

    case RESET_CURRENT_SEARCH_ID:
      return {
        ...state,
        bulkSearch: {
          ...state.bulkSearch,
          currentSearch: null,
        },
      };
    case RESET_BULK_SEARCHES_REPLACEMENT:
      return {
        ...state,
        bulkSearch: { ...initialState.bulkSearch },
      };
    case SET_BULK_SEARCH_UPDATE:
      return {
        ...state,
        bulkSearch: {
          ...state.bulkSearch,
          updateInProgress: action.payload.updateInProgress,
        },
      };
    case CLEAR_WIDGET_DATA:
      return {
        ...state,
        widgetV3ById: {},
      };
    case CLEAR_ANALYTIC:
      return {
        ...state,
        dashboard: null,
      };
    case CLEAR_DUPLICATE_ANALYTIC:
      return {
        ...state,
        duplicatingSuccess: false,
        duplicateDashboardId: null,
      };
    case DUPLICATE_ANALYTIC:
      return {
        ...state,
        duplicatingSuccess: false,
        duplicateDashboardId: null,
      };
    case DUPLICATE_ANALYTIC_ERROR:
      return {
        ...state,
        duplicatingSuccess: false,
        duplicateDashboardId: null,
      };
    case DUPLICATE_ANALYTIC_SUCCESS:
      return {
        ...state,
        duplicatingSuccess: true,
        duplicateDashboardId: action.payload.id,
      };
    case UPDATE_LAYOUT:
      return {
        ...state,
        layoutError: false,
      };
    case UPDATE_LAYOUT_ERROR:
      return {
        ...state,
        layoutError: true,
      };
    case UPDATE_LAYOUT_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...(state.dashboard as Dashboard | null),
          layout: [...action.payload],
        },
        layoutError: false,
      };
    case CREATE_TILE:
      return {
        ...state,
        creatingTile: true,
        createTileError: null,
      };
    case CREATE_TILE_ERROR:
      return {
        ...state,
        creatingTile: false,
        createTileError: action.payload,
      };
    case CREATE_V3_TILE_SUCCESS: {
      const layout = (state.dashboard as Dashboard | null)?.layout
        ? (state.dashboard as Dashboard | null)?.layout
        : [];
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      return {
        ...state,
        dashboard: {
          ...(state.dashboard as Dashboard | null),
          layout: [...(layout as AnalyticSlideLayout[]), action.payload.tile],
        },
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.tile.contentId]: {
            ...action.payload.content,
            type: WIDGET_TYPES.widgetV3,
          },
        },
        creatingTile: false,
      };
    }
    case CREATE_WIDGET: {
      return {
        ...state,
        creatingWidget: true,
        createWidgetError: null,
      };
    }
    case CREATE_WIDGET_ERROR: {
      return {
        ...state,
        creatingWidget: false,
        createWidgetError: action.payload,
      };
    }
    case CREATE_WIDGET_SUCCESS: {
      return {
        ...state,
        creatingWidget: false,
        createWidgetError: false,
      };
    }
    case DELETE_V3_TILE: {
      const widgetId = action.payload;
      const newMap = { ...state.widgetV3ById };
      delete newMap[widgetId];
      return {
        ...state,
        dashboard: {
          ...(state.dashboard as Dashboard | null),
          layout: (state.dashboard as Dashboard | null)?.layout.filter(
            x => x.contentId !== widgetId,
          ),
        },
        widgetV3ById: newMap,
      };
    }
    case GET_V3_WIDGET: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];

      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            widgetError: false,
            widgetErrorMessage: '',
            loadingWidget: true,
            widgetType: WIDGET_TYPE.unknown,
          },
        },
      };
    }
    case GET_V3_WIDGET_SUCCESS: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            ...action.payload.data,
            widgetType: action.payload.widgetType,
            chartType: action.payload.chartType,
            loadingWidget: false,
            loadingChartData: true,
            widgetError: false,
            widgetErrorMessage: '',
          },
        },
      };
    }
    case GET_V3_WIDGET_ERROR: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            widgetError: true,
            loadingWidget: false,
          },
        },
      };
    }
    case GET_V3_CHART_DATA: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            chartData: null,
            widgetError: false,
            widgetErrorMessage: '',
            socialDateRangeExceeded: false,
            loadingChartData: true,
          },
        },
      };
    }
    case GET_V3_CHART_DATA_ERROR: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];
      const { widgetErrorMessage } = action.payload;
      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            widgetError: true,
            socialDateRangeExceeded: action.payload.socialDateRangeExceeded,
            widgetErrorMessage,
            loadingChartData: false,
          },
        },
      };
    }
    case GET_V3_CHART_DATA_SUCCESS: {
      const widgetV3ById = state.widgetV3ById ? state.widgetV3ById : {};
      const widget = widgetV3ById[action.payload.id];
      // The API does not return results in a consistent order which can impact
      // the appearance of data across refreshes (ie different color assignments)
      // Sort them by the data source source id which should result in consistent ordering
      const sortedChartData = action.payload.data.map(chartData => ({
        ...chartData,
        data: sortBySourcesId(chartData.data),
      }));
      if (widget && widget.metrics) {
        widget.metrics.forEach(m => {
          const matchingMetric = action.payload.data.find(
            d => d.measure?.id === m.measure?.id,
          );
          if (matchingMetric) {
            m.dataSources = matchingMetric.dataSources;
          }
        });
      }
      return {
        ...state,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            chartData: sortedChartData,
            loadingChartData: false,
            widgetError: false,
            widgetErrorMessage: '',
            socialDateRangeExceeded: false,
          },
        },
      };
    }
    case UPDATE_V3_WIDGET: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: null,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            updatingWidget: true,
          },
        },
      };
    }
    case UPDATE_V3_WIDGET_SUCCESS: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: false,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            ...action.payload.content,
            updatingWidget: false,
            widgetType: action.payload.widgetType,
            chartType: action.payload.chartType,
            chartData: null,
          },
        },
      };
    }
    case UPDATE_V3_WIDGET_ERROR: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: action.payload.error,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            updatingWidget: false,
          },
        },
      };
    }
    case SET_WIDGET_DATERANGE: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: null,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            updatingWidget: true,
          },
        },
      };
    }
    case SET_WIDGET_DATERANGE_SUCCESS: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: null,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            ...action.payload.content,
            updatingWidget: false,
          },
        },
      };
    }
    case SET_WIDGET_DATERANGE_ERROR: {
      const widgetV3ById = state.widgetV3ById || {};
      const widget = widgetV3ById[action.payload.id];
      return {
        ...state,
        updatingWidgetError: action.payload.error,
        widgetV3ById: {
          ...widgetV3ById,
          [action.payload.id]: {
            ...widget,
            updatingWidget: false,
          },
        },
      };
    }
    case ADD_DASHBOARD_TO_CAMPAIGNS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case EXPORT_DASHBOARD_TO_CSV: {
      return {
        ...state,
        export: {
          initiateExport: true,
          exportedWidgets: action.payload.exportedWidgets,
        },
      };
    }
    case SET_DASHBOARD_CSV_EXPORT_TYPE: {
      return {
        ...state,
        export: {
          csvExportType: action.payload.csvExportType,
        },
      };
    }

    case SET_WIDGETS_TO_RELOAD: {
      return {
        ...state,
        widgetsToReload: action.payload,
      };
    }

    case DELETE_WIDGET_FROM_RELOAD_LIST: {
      return {
        ...state,
        widgetsToReload: state.widgetsToReload.filter(
          id => id !== action.payload,
        ),
      };
    }

    case CLEAR_WIDGETS_TO_RELOAD: {
      return {
        ...state,
        widgetsToReload: initialState.widgetsToReload,
      };
    }

    case SET_DASHBOARDS_LISTS_SIDE_TRAY_OPEN: {
      return {
        ...state,
        isDashboardsListsSideTrayOpen: action.payload,
      };
    }

    case SET_SHARED_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...(state.dashboard || {}),
          shared: action.payload.isShared,
        },
      };

    case RESET_CREATE_DASHBOARD_FIELDS:
      return {
        ...state,
        isDashboardCreated: false,
        errorCreatingDashboard: null,
      };

    case IS_DASHBOARD_VIEW_AND_HAS_ARCHIVE_DELETE_DASHBOARD_FF:
      return {
        ...state,
        isDashboardViewAndHasArchiveDeleteDashboardsFF: action.payload,
      };

    case UPDATE_SEARCHES_BY_DASHBOARD:
      return {
        ...state,
        dashboardSearches: {
          ...state.dashboardSearches,
          ...action.payload,
        },
      };

    case WIDGET_SHOULD_USE_BACKEND_CACHE:
      return {
        ...state,
        shouldWidgetUseBackendCache: action.payload,
      };

    case CLEAR_ALL_DASHBOARD_SEARCHES:
      return {
        ...state,
        dashboardSearches: {},
      };

    default:
      return state;
  }
};

export const bustWidgetDataCache = id => {
  const urlMatcherForCacheBusting = new RegExp(
    `${WIDGET_V3_ENDPOINT}/${id}/data`,
  );
  restServiceCache.deleteKeysByUrl(urlMatcherForCacheBusting);
};

export const bustAllWidgetDataCache = () => {
  const urlMatcherForCacheBusting = new RegExp(
    `${WIDGET_V3_ENDPOINT}/[0-9]+/data`,
  );
  restServiceCache.deleteKeysByUrl(urlMatcherForCacheBusting);
};

export const resetCurrentSearch = () => dispatch =>
  dispatch({ type: RESET_CURRENT_SEARCH_ID });

export const updateBulkSearches = (
  currentSearchId,
  currentSearch,
) => dispatch => {
  dispatch({
    type: SET_BULK_SEARCHES_REPLACEMENT,
    payload: { currentSearchId, currentSearch },
  });
};

export const setCurrentSearch = currentSearch => dispatch => {
  dispatch({ type: SET_CURRENT_SEARCH_ID, payload: currentSearch });
};

export const resetBulkSearches = () => dispatch =>
  dispatch({ type: RESET_BULK_SEARCHES_REPLACEMENT });

export const setBulkSearchUpdateInProgress = (
  updateInProgress: boolean,
) => dispatch => {
  dispatch({ type: SET_BULK_SEARCH_UPDATE, payload: { updateInProgress } });
};

export const createDashboardActionCreator = () => async dispatch => {
  dispatch({ type: CREATE_DASHBOARD });
  try {
    const result = await csrfPerformPost(NEW_DASHBOARD_ENDPOINT, {
      title: '',
      dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
    });
    dispatch({
      type: CREATE_DASHBOARD_SUCCESS,
      payload: { dashboard: result.data },
    });
  } catch (e) {
    dispatch({
      type: CREATE_DASHBOARD_ERROR,
      payload: { error: e.response.data },
    });
    dispatch(
      addPageMessage({
        text: e.response?.data?.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};
export const duplicateAnalyticActionCreator = (
  dashboardId,
  successMessage,
  endpointBase = NEW_DASHBOARD_ENDPOINT,
) => async dispatch => {
  try {
    dispatch({ type: DUPLICATE_ANALYTIC });
    const result = await performPost(
      `${endpointBase}/${dashboardId}/duplicate`,
      {},
    );
    dispatch({
      type: DUPLICATE_ANALYTIC_SUCCESS,
      payload: {
        id: result.data.uuid ? result.data.uuid + '/edit' : result.data.id,
      },
    });
    dispatch(
      addPageMessage({
        text: successMessage(result.data.title),
        status: 'success',
        ttl: 6000,
      }),
    );
  } catch (e) {
    dispatch({ type: DUPLICATE_ANALYTIC_ERROR });
    dispatch(
      addPageMessage({
        text: e.response?.data?.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export const getDashboardActionCreator = (
  id,
  endpointBase = NEW_DASHBOARD_ENDPOINT,
) => async dispatch => {
  let result;
  dispatch({ type: GET_DASHBOARD });
  dispatch({ type: CLEAR_WIDGET_DATA });

  try {
    result = await performGet(`${endpointBase}/${id}`);
  } catch (e) {
    dispatch({
      type: GET_DASHBOARD_ERROR,
      payload: { error: { status: e.response.status, message: e.message } },
    });
    throw e;
  }

  dispatch({
    type: GET_DASHBOARD_SUCCESS,
    payload: { dashboard: result.data },
  });
};

export const updateDashboardArchiveStatus = (ids, isArchived) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const dashboard = state.dashboard.dashboardHub.dashboard;
  const dashboardId = dashboard?.id;

  for (const id of ids) {
    if (dashboardId === id) {
      dispatch({
        type: UPDATE_DASHBOARD_ARCHIVE_STATUS,
        payload: {
          dashboard: {
            ...dashboard,
            archived: isArchived,
          },
        },
      });
      break;
    }
  }
};

const gridWidth = 3;
// Add new 1x2 tile to layout in first availble spot, searching left to right, top to bottom
const createNewTile = (
  widget,
  layout: AnalyticSlideLayout[],
  dashboardId,
  type,
) => {
  // layout matrix is filled with true if there is a tile in that spot
  const layoutMatrix: boolean[][] = [];

  layout.forEach(t => {
    for (let i = t.gridY; i < t.gridY + t.gridHeight; i += 1) {
      const spotRow: boolean[] = layoutMatrix[i] || [];
      for (let j = t.gridX; j < t.gridX + t.gridWidth; j += 1) {
        spotRow[j] = true;
      }
      layoutMatrix[i] = spotRow;
    }
  });

  let currentSpot = [0, 0];
  for (let a = 0; a < layoutMatrix.length; a += 1) {
    const row = layoutMatrix[a];
    const nextRow = layoutMatrix[a + 1] || [];
    for (let b = 0; b < gridWidth; b += 1) {
      // see if there is a tile in current spot or in
      // spot directly below current
      const spot = row[b];
      const spotDown = nextRow[b];
      if (spot || spotDown) {
        currentSpot[1] += 1;
      } else {
        break;
      }
    }
    if (currentSpot[1] >= gridWidth) {
      currentSpot = [currentSpot[0] + 1, 0];
    } else {
      break;
    }
  }

  const params = {
    gridY: currentSpot[0],
    gridX: currentSpot[1],
    gridWidth: 1,
    gridHeight: 2,
    type: type,
    contentId: widget.id,
  };

  return performPost(`${NEW_DASHBOARD_ENDPOINT}/${dashboardId}/tile`, params);
};

const hasWidgetReachedSearchesLimit = errorData => {
  if (!errorData.response) {
    return false;
  }
  const { status, data } = errorData.response;
  return status === 403 && data.limitReached;
};

export const createMetricsForWidget = widgetForm => {
  return convertWidgetFormToMetrics(widgetForm)?.map(metric => {
    return { ...metric, dateRangeType: DATE_RANGE_KEYS.TRAILING_30 };
  });
};

export const createWidgetPayload = (title, dashboardId, metrics) => {
  return {
    title,
    dashboardId,
    metrics,
  };
};

export const createV3Widget = widgetForm => {
  const metrics = createMetricsForWidget(widgetForm);
  return performPost(
    WIDGET_V3_ENDPOINT,
    createWidgetPayload(widgetForm.title, widgetForm.dashboardId, metrics),
  );
};

export const addNewWidgetToLayoutActionCreator = widgetForm => async (
  dispatch,
  getState,
) => {
  const { dashboardId } = widgetForm;
  const state = getState();
  const layout = state.dashboard.dashboardHub.dashboard.layout;
  let widgetResponse = null;

  try {
    dispatch({ type: CREATE_WIDGET });
    widgetResponse = await createV3Widget(widgetForm);
  } catch (e) {
    if (hasWidgetReachedSearchesLimit(e)) {
      dispatch(
        addPageMessage({
          text: e.response.data.error,
          status: 'danger',
          ttl: 6000,
        }),
      );
    } else {
      dispatch({ type: CREATE_WIDGET_ERROR, payload: e.message });
    }
  }
  if (widgetResponse) {
    const widget = (widgetResponse as any).data;
    try {
      dispatch({ type: CREATE_TILE });
      const tileCreateResponse = await createNewTile(
        widget,
        layout,
        dashboardId,
        WIDGET_TYPES.widgetV3,
      );
      const tile = tileCreateResponse.data;
      dispatch({
        type: CREATE_V3_TILE_SUCCESS,
        payload: { tile, content: widget },
      });
      //* widget added notification message
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: widgetForm?.notificationText,
          status: MessagesStatus.SUCCESS,
          isCancelable: false,
        }),
      );

      //* Issue with react-grid-layout component that is setting a page height larger than scroll height
      //* First scroll to the bottom of the page
      window.scrollTo(0, document.body.scrollHeight - headerOffset);

      //* Then use the real window.scrollY to scroll into the bottom of the page less the offset/
      window.scrollTo(0, window.scrollY - headerOffset);
    } catch (e) {
      dispatch({ type: CREATE_TILE_ERROR, payload: e.message });
    }
  }
};

const updateWidgetOnSuccessHandler = (
  dispatch,
  widget,
  data,
  intl,
  dateRange = null,
) => {
  const currentDateRange = dateRange || getDateRange(widget.metrics);
  const { type, startDate, endDate } = currentDateRange || {};

  const getTimestampsForRange = isSocialSearch(widget.metrics)
    ? getTimestampsForAnySocialRange
    : getTimestampsForAnyRange;

  const times = getTimestampsForRange(type, startDate, endDate);

  const dateRangeCompareMetric = widget.metrics.find(
    metric => metric.isDateRangeCompare,
  );
  const dateRangeCompareType =
    dateRangeCompareMetric?.dateRangeCompareType || null;
  let compareStartDate = null;
  let compareEndDate = null;

  if (dateRangeCompareType) {
    const compareTimes = buildDateRangeCompareTimestamps(
      dateRangeCompareType,
      currentDateRange,
      moment.utc(dateRangeCompareMetric.startDate).valueOf(),
      moment.utc(dateRangeCompareMetric.endDate).valueOf(),
    );
    compareStartDate = compareTimes.compareStartDate;
    compareEndDate = compareTimes.compareEndDate;
  }

  const widgetType = getWidgetTypeFromMetrics(widget.metrics);
  const chartType = getChartTypeFromMetrics(widget.metrics);

  dispatch({
    type: UPDATE_V3_WIDGET_SUCCESS,
    payload: { id: data.id, content: data, widgetType, chartType },
  });

  dispatch(
    getChartDataFromV3WidgetIdActionCreator({
      id: widget.id,
      startDate: times?.startTime,
      endDate: times?.endTime,
      compareStartDate,
      compareEndDate,
      intl,
      skipCache: true,
    }),
  );
};

export const updateV3WidgetActionCreator: (arg: {
  widgetForm: any;
  convertWidget: boolean;
  dateRange?: any;
  dashboardId: number;
  intl?: IntlFormatters;
}) => void = ({
  widgetForm,
  convertWidget = true,
  dateRange = null,
  dashboardId,
  intl,
}) => async dispatch => {
  const widget = convertWidget
    ? convertWidgetFormToV3Widget(widgetForm)
    : widgetForm;
  dispatch({ type: UPDATE_V3_WIDGET, payload: { id: widget.id } });

  bustWidgetDataCache(widget.id);
  try {
    if (widget.title === '' || widget.title == null) {
      widget.title = intl?.formatMessage(
        dashboardBulSearchMessages.untitleWidget,
      );
    }
    const { data } = await performPut(`api/v3/widget/${widget.id}`, {
      ...widget,
      dashboardId,
    });

    updateWidgetOnSuccessHandler(dispatch, widget, data, intl, dateRange);
    //* widget update notification message
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: widgetForm?.notificationText,
        status: MessagesStatus.SUCCESS,
        isCancelable: false,
      }),
    );
  } catch (e) {
    dispatch({
      type: UPDATE_V3_WIDGET_ERROR,
      payload: { error: e.message, id: widget.id },
    });
  }
};

export const updateBulkSearchWidgetActionCreator = (
  dashboardId: number,
  intl: IntlFormatters,
) => async (dispatch: Dispatch<any>, getState: () => any): Promise<void> => {
  const state = getState();
  const widgetsBulkUpdate = buildBulkSearchPayloadSelector(state);
  setBulkSearchUpdateInProgress(true);
  const bulkUpdatePromise = widgetsBulkUpdate.map(widget => {
    dispatch({ type: UPDATE_V3_WIDGET, payload: { id: widget.id } });
    return performPut(`api/v3/widget/${widget.id}`, widget).catch(error => ({
      error,
    }));
  });

  const updateResults = await Promise.all(bulkUpdatePromise);
  updateResults.forEach((widgetUpdate: any, index) => {
    const { data, error } = widgetUpdate;
    const widget = widgetsBulkUpdate[index];

    if (data) {
      updateWidgetOnSuccessHandler(dispatch, widget, data, intl);
      return;
    }

    dispatch({
      type: UPDATE_V3_WIDGET_ERROR,
      payload: { error: error.message, id: widget.id },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          dashboardBulSearchMessages.updateErrorByWidget,
          {
            widgetName: widget.title,
          },
        ),
        status: 'danger',
      }),
    );
  });
  setBulkSearchUpdateInProgress(false);
};

export const duplicateWidgetToDashboardActionCreator = (
  widgetId,
  dashboardId,
  successMessage,
) => async (dispatch, getState) => {
  try {
    const { data: widget } = await performPost(
      `${WIDGET_V3_ENDPOINT}/${widgetId}/duplicate`,
      {
        dashboardId,
      },
    );
    const dashboardResult = await performGet(
      `${NEW_DASHBOARD_ENDPOINT}/${dashboardId}`,
    );
    const tileResult = await createNewTile(
      widget,
      dashboardResult.data.layout,
      dashboardId,
      WIDGET_TYPES.widgetV3,
    );

    const state = getState();
    const currentDashboardId = state.dashboard.dashboardHub.dashboard.id;
    if (dashboardId === currentDashboardId) {
      dispatch({
        type: CREATE_V3_TILE_SUCCESS,
        payload: { tile: tileResult.data, content: widget },
      });
    }
    dispatch(
      addPageMessage({
        text: successMessage(widget, dashboardResult.data),
        status: 'success',
        ttl: 6000,
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessage({
        text: e.response.data.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export const updateWidgetV3DateRangeCompare = ({
  widget,
  compareType,
  customDate = null,
  dateRange,
  compareDateRange,
  intl,
}) => async dispatch => {
  dispatch({
    type: SET_WIDGET_DATERANGE,
    payload: { id: widget.id, type: widget.type },
  });
  try {
    const compareStartDate = customDate
      ? (customDate as any)?.startDate
      : compareDateRange?.compareStartDate;
    const compareEndDate = customDate
      ? (customDate as any)?.endDate
      : compareDateRange?.compareEndDate;
    const mainMetric = widget.metrics.find(m => !m.isDateRangeCompare);
    let dateRangeCompareMetric = widget.metrics.find(m => m.isDateRangeCompare);

    if (!compareType && dateRangeCompareMetric) {
      widget.metrics.splice(widget.metrics.indexOf(dateRangeCompareMetric), 1);
    } else {
      if (!dateRangeCompareMetric) {
        dateRangeCompareMetric = createDateRangeCompareMetric(
          mainMetric,
          compareType,
          customDate,
        );
        widget.metrics.push(dateRangeCompareMetric);
      }

      dateRangeCompareMetric.dateRangeCompareType = compareType;
      dateRangeCompareMetric.dateRangeType =
        compareType === DATE_RANGE_COMPARE_TYPES.customStartDate
          ? DATE_RANGE_KEYS.CUSTOM
          : DATE_RANGE_KEYS.TRAILING_90;
      dateRangeCompareMetric.startDate = (customDate as any)?.startDate;
      dateRangeCompareMetric.endDate = (customDate as any)?.endDate;
    }

    const { data } = await performPut(`${WIDGET_V3_ENDPOINT}/${widget.id}`, {
      ...widget,
    });

    dispatch({
      type: SET_WIDGET_DATERANGE_SUCCESS,
      payload: { id: widget.id, content: data, type: widget.type },
    });

    dispatch(
      getChartDataFromV3WidgetIdActionCreator({
        id: widget.id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        compareStartDate,
        compareEndDate,
        intl,
        skipCache: true,
      }),
    );
  } catch (e) {
    dispatch({
      type: SET_WIDGET_DATERANGE_ERROR,
      payload: { error: e.message, id: widget.id, type: widget.type },
    });
  }
};

export const updateWidgetDateRange = widget => async dispatch => {
  dispatch({
    type: SET_WIDGET_DATERANGE,
    payload: { id: widget.id, type: widget.type },
  });
  try {
    const dateRange = getDateRange(widget.metrics);
    const dateRangeCompareMetric = widget.metrics.find(
      metric => metric.isDateRangeCompare,
    );
    const { data } = await performPut(`${WIDGET_V3_ENDPOINT}/${widget.id}`, {
      ...widget,
    });

    dispatch({
      type: SET_WIDGET_DATERANGE_SUCCESS,
      payload: { id: widget.id, content: data, type: widget.type },
    });

    const dateRangeCompareType =
      dateRangeCompareMetric?.dateRangeCompareType || null;
    let compareStartDate = null;
    let compareEndDate = null;
    if (dateRangeCompareType) {
      const {
        startDate: compareCustomStartDate,
        endDate: compareCustomEndDate,
      } = getWidgetV3CompareDateRange(widget.metrics) || {};
      const compareTimes = buildDateRangeCompareTimestamps(
        dateRangeCompareType,
        dateRange,
        compareCustomStartDate,
        compareCustomEndDate,
      );
      compareStartDate = compareTimes.compareStartDate;
      compareEndDate = compareTimes.compareEndDate;
    }

    const { type, startDate, endDate } = dateRange || {};
    const getTimestampsForRange = isSocialSearch(widget.metrics)
      ? getTimestampsForAnySocialRange
      : getTimestampsForAnyRange;

    const { endTime } = getTimestampsForRange(type, startDate, endDate) || {};

    dispatch(
      getChartDataFromV3WidgetIdActionCreator({
        id: widget.id,
        startDate: (dateRange as any)?.startDate,
        endDate: isSocialSearch(widget.metrics)
          ? endTime
          : (dateRange as any)?.endDate,
        compareStartDate,
        compareEndDate,
        skipCache: true,
      }),
    );
  } catch (e) {
    dispatch({
      type: SET_WIDGET_DATERANGE_ERROR,
      payload: { error: e.message, id: widget.id, type: widget.type },
    });
  }
};

export const updateDashboardDateRange = (
  dashboardId,
  dateRange,
) => async dispatch => {
  try {
    await performPatch(`api/v2/dashboard/${dashboardId}/dateRange`, dateRange);
    dispatch(getDashboardActionCreator(dashboardId, NEW_DASHBOARD_ENDPOINT));
  } catch (e) {
    dispatch(
      addPageMessage({
        text: e.response.data.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export const deleteWidgetFromLayoutActionCreator = ({
  widgetId,
  notificationText,
  dashboardId,
}: {
  widgetId: string;
  notificationText: string;
  dashboardId: number;
}) => async (dispatch, getState) => {
  const state = getState();
  const layout = state.dashboard.dashboardHub.dashboard.layout;
  const tile = layout.find(({ contentId }) => contentId === widgetId);

  if (tile) {
    const tileId = tile.id;

    try {
      await performDelete(`${TILE_ENDPOINT}/${tileId}`, { dashboardId });
      dispatch({ type: DELETE_V3_TILE, payload: widgetId });
      //* Widget removed notification message
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: notificationText,
          status: MessagesStatus.SUCCESS,
          isCancelable: false,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_LAYOUT_ERROR });
      throw e;
    }
  }
};

const getChartDataWithWidgetId = (
  id,
  startDate,
  endDate,
  mediaTypeFilters,
  compareStartDate = null,
  compareEndDate = null,
  reportUuid = '',
  transientWidgetId = null,
  widget,
  isReloadOfFailure = false,
  skipCache = false,
  skipFrontendCache = false,
  useBackendCache = false,
  retryOnError = false,
) => {
  const dateRangeParams = buildDateRangeParams({
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
  });

  const path = reportUuid
    ? `${NEW_REPORT_ENDPOINT}/${reportUuid}/widget`
    : WIDGET_V3_ENDPOINT;

  const useCache =
    userHasDevFeatureFlag(DEV_FEATURES.cacheWidgetData) &&
    !reportUuid &&
    !skipFrontendCache;

  const shouldAddBackendCacheParams =
    userHasDevFeatureFlag(DEV_FEATURES.backendWidgetV3Cache) && !reportUuid;

  if (transientWidgetId) {
    const payload = buildTransientWidgetRequest(
      buildSocialTransientWidgetData(id),
    );
    const dateRange = {
      startDate,
      endDate,
    };
    return performPost(`${WIDGET_V3_ENDPOINT}/data`, payload, dateRange);
  } else {
    if (
      widget?.metrics?.length > 1 ||
      widget?.metrics[0]?.dataSources.length > 3 ||
      widget?.metrics[0]?.visualizationType === 'WORD_CLOUD' ||
      isReloadOfFailure
    ) {
      const searchIdsHash = getWidgetSearchIdsHash(widget?.metrics);
      return performGet(
        `${path}/${id}/data`,
        {
          ...dateRangeParams,
          viewFilters: mediaTypeFilters,
          ...(searchIdsHash ? { searchIds: searchIdsHash } : {}),
          skipCache: shouldAddBackendCacheParams ? skipCache : undefined,
          retryOnError: shouldAddBackendCacheParams ? retryOnError : undefined,
          useBackendCache: shouldAddBackendCacheParams
            ? useBackendCache
            : undefined,
        },
        null,
        null,
        useCache,
        1,
      );
    } else {
      const searchIdsHash = getWidgetSearchIdsHash(widget?.metrics);
      return performGet(
        `${path}/${id}/data`,
        {
          ...dateRangeParams,
          viewFilters: mediaTypeFilters,
          ...(searchIdsHash ? { searchIds: searchIdsHash } : {}),
          skipCache: shouldAddBackendCacheParams ? skipCache : undefined,
          retryOnError: shouldAddBackendCacheParams ? retryOnError : undefined,
          useBackendCache: shouldAddBackendCacheParams
            ? useBackendCache
            : undefined,
        },
        null,
        null,
        useCache,
        10,
      );
    }
  }
};

export const resetCreateDashboardProperties = () => ({
  type: RESET_CREATE_DASHBOARD_FIELDS,
});

export const updateIsDashboardViewAndHasArchiveDeleteDashboardsFF = isTrue => ({
  type: IS_DASHBOARD_VIEW_AND_HAS_ARCHIVE_DELETE_DASHBOARD_FF,
  payload: isTrue,
});

export const clearAllDashboardSearches = () => ({
  type: CLEAR_ALL_DASHBOARD_SEARCHES,
});

export const getDashboardSearchesActionCreator = dashboardId => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const dashboardSearchesInfo =
    state.dashboard?.dashboardHub?.dashboardSearches?.[dashboardId] || {};

  const { loading, loaded, error } = dashboardSearchesInfo;

  if (!loading && (!loaded || error)) {
    dispatch({
      type: UPDATE_SEARCHES_BY_DASHBOARD,
      payload: {
        [dashboardId]: { loading: true, loaded: false, error: false, data: [] },
      },
    });

    try {
      const response = await performGet(
        `${DASHBOARD_ENDPOINT_V2}/${dashboardId}/searches`,
      );

      dispatch({
        type: UPDATE_SEARCHES_BY_DASHBOARD,
        payload: {
          [dashboardId]: { loading: false, loaded: true, data: response.data },
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_SEARCHES_BY_DASHBOARD,
        payload: {
          [dashboardId]: { loading: false, error: true },
        },
      });
      throw e;
    }
  }
};

export const getWidgetV3ByIdActionCreator = ({
  id,
  reportUuid = '',
  isUsingCache = false,
  checkDeletedDashboards = false,
}) => async (dispatch, getState) => {
  const state = getState();
  const widget = state.dashboard?.dashboardHub?.widgetV3ById?.[id] || {};

  const { widgetError, metrics, widgetId = id, widgetType, chartType } = widget;
  if (
    isUsingCache &&
    !widgetError &&
    metrics &&
    widgetId &&
    widgetType &&
    chartType
  ) {
    dispatch({
      type: GET_V3_WIDGET_SUCCESS,
      payload: { ...widget },
    });

    return;
  }

  dispatch({ type: GET_V3_WIDGET, payload: { id } });
  try {
    const path = reportUuid
      ? `${NEW_REPORT_ENDPOINT}/${reportUuid}/widget`
      : `${WIDGET_V3_ENDPOINT}`;

    const concatCheckDeletedDashboards =
      checkDeletedDashboards && !reportUuid
        ? `?checkDeletedDashboards=${checkDeletedDashboards}`
        : '';

    const response = await performGet(
      `${path}/${id}${concatCheckDeletedDashboards}`,
    );
    const widgetType = getWidgetTypeFromMetrics(response.data.metrics);
    const chartType = getChartTypeFromMetrics(response.data.metrics);
    dispatch({
      type: GET_V3_WIDGET_SUCCESS,
      payload: { data: response.data, id, widgetType, chartType },
    });
  } catch (e) {
    dispatch({ type: GET_V3_WIDGET_ERROR, payload: { id } });
    throw e;
  }
};

const hasSocialWidgetExceededDateRangeLimit = errorData => {
  if (!errorData.response) {
    return false;
  }
  const { status, data } = errorData.response;
  return (
    status === 404 &&
    data?.error?.includes('error:Twitter') &&
    (data.error.includes('fromDate') || data.error.includes('toDate'))
  );
};

const sleep = seconds => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => resolve(), seconds * 1000);
  });
};

const getWidgetDataHandler = async (
  { skipCache, useBackendWidgetCache },
  cb,
) => {
  if (useBackendWidgetCache) {
    let widgetData;
    let retryCount = 0;
    while (retryCount < WIDGETS_V3_BACKEND_CACHE_RETRY_LIMIT) {
      const response = await cb(null, {
        shouldSkipCache: skipCache && retryCount === 0,
        shouldSkipFrontendCache: retryCount > 0,
        retryOnError: retryCount === 0,
      });
      if (response.data.data) {
        widgetData = response;
        break;
      } else if (response.data.error) {
        throw new Error('Error while getting widget data');
      }
      await sleep(WIDGETS_V3_BACKEND_CACHE_RETRY_INTERVAL);
      retryCount += 1;
    }
    if (!widgetData) {
      throw new Error('Widget data was not retrieved');
    }
    return widgetData;
  } else {
    return cb(null, { retryOnError: false });
  }
};

export const getChartDataFromV3WidgetIdActionCreator = ({
  id,
  startDate = null,
  endDate = null,
  mediaTypeFilters = [],
  compareStartDate = null,
  compareEndDate = null,
  reportUuid = '',
  transientWidgetId = null,
  intl = null,
  cacheDateRangeType = null,
  skipCache = false,
}: {
  id: any;
  startDate: any;
  endDate: any;
  compareStartDate?: any;
  compareEndDate?: any;
  mediaTypeFilters?: any;
  reportUuid?: any;
  transientWidgetId?: any;
  intl?: any;
  cacheDateRangeType?: string | null;
  skipCache?: boolean;
}) => async (dispatch, getState) => {
  const widgetId = transientWidgetId || id;
  const state = getState();
  const widget = state.dashboard.dashboardHub.widgetV3ById[widgetId];
  const shouldUseBackendCache = shouldWidgetUseBackendCacheSelector(state);
  const chartData = widget?.chartData?.[0]?.dateRange;

  const useBackendWidgetCache =
    userHasDevFeatureFlag(DEV_FEATURES.backendWidgetV3Cache) &&
    shouldUseBackendCache;

  if (!widget?.widgetError && chartData?.dateRangeType === cacheDateRangeType) {
    // Will only check against the start/end date if the date range type is CUSTOM
    const hasCustomDateRangeChanged =
      cacheDateRangeType === 'CUSTOM' &&
      (chartData.startDate !== startDate || chartData.endDate !== endDate);

    if (!hasCustomDateRangeChanged) {
      dispatch({
        type: GET_V3_CHART_DATA_SUCCESS,
        payload: {
          data: widget.chartData,
          id: widgetId,
        },
      });
      return;
    }
  }

  dispatch({ type: GET_V3_CHART_DATA, payload: { id: widgetId } });
  let response;
  try {
    response = await getWidgetDataHandler(
      { skipCache, useBackendWidgetCache },
      async (
        _,
        {
          shouldSkipCache = false,
          shouldSkipFrontendCache = false,
          retryOnError = undefined,
        },
      ) => {
        return getChartDataWithWidgetId(
          id,
          startDate,
          endDate,
          mediaTypeFilters,
          compareStartDate,
          compareEndDate,
          reportUuid,
          transientWidgetId,
          widget,
          false,
          shouldSkipCache,
          shouldSkipFrontendCache,
          useBackendWidgetCache,
          retryOnError,
        );
      },
    );
  } catch (e) {
    if (!e.response) {
      dispatch({
        type: GET_V3_CHART_DATA_ERROR,
        payload: {
          id: widgetId,
          widgetErrorMessage: 'Error getting widget data',
        },
      });
      return;
    }
    // check NOT FOUND error, set in WAG to return 404 if social data Date Range is exceeded.
    const socialDateRangeExceeded = hasSocialWidgetExceededDateRangeLimit(e);
    const errorMessage = e?.response?.data?.error;
    dispatch({
      type: GET_V3_CHART_DATA_ERROR,
      payload: {
        id: widgetId,
        widgetErrorMessage: errorMessage,
        socialDateRangeExceeded,
      },
    });

    if (e?.response?.status === 500) {
      try {
        response = await getWidgetDataHandler(
          { skipCache, useBackendWidgetCache },
          async (
            _,
            {
              shouldSkipCache = false,
              shouldSkipFrontendCache = false,
              retryOnError = undefined,
            },
          ) => {
            return getChartDataWithWidgetId(
              id,
              startDate,
              endDate,
              mediaTypeFilters,
              compareStartDate,
              compareEndDate,
              reportUuid,
              transientWidgetId,
              widget,
              true,
              shouldSkipCache,
              shouldSkipFrontendCache,
              useBackendWidgetCache,
              retryOnError,
            );
          },
        );
      } catch (e) {
        if (!e.response) {
          dispatch({
            type: GET_V3_CHART_DATA_ERROR,
            payload: {
              id: widgetId,
              widgetErrorMessage: 'Error getting widget data',
            },
          });
          return;
        }
        // check NOT FOUND error, set in WAG to return 404 if social data Date Range is exceeded.
        const socialDateRangeExceeded = hasSocialWidgetExceededDateRangeLimit(
          e,
        );
        const errorMessage = e?.response?.data?.error;
        dispatch({
          type: GET_V3_CHART_DATA_ERROR,
          payload: {
            id: widgetId,
            widgetErrorMessage: errorMessage,
            socialDateRangeExceeded,
          },
        });

        throw e;
      }
    } else {
      throw e;
    }
  }

  const hydratedChartData = hydrateWidgetData(widget, response.data.data);
  const responseData = featureFilterData(hydratedChartData);

  if (
    intl &&
    window.activeUser.language === 'fr-ca' &&
    responseData?.[0]?.visualizationType === 'ARTICLE_LIST' &&
    responseData?.[0]?.data?.[0]?.data?.[0]?.articles
  ) {
    responseData[0].data[0].data[0].articles = responseData[0].data[0].data[0].articles.map(
      article => {
        if (article.data_source?.toLowerCase() === 'tveyes') {
          const title = intl.formatMessage(
            messages.tvEyesRadioClipFromPublisherTitle,
            {
              PUBLISHER: article.publisher,
              DATE: intl.formatDate(
                moment(article.publishDate),
                DEFAULT_DATE_FORMAT_INTL,
              ),
            },
          );
          return { ...article, title };
        }

        return article;
      },
    );
  }

  dispatch({
    type: GET_V3_CHART_DATA_SUCCESS,
    payload: {
      data: responseData,
      id: widgetId,
    },
  });
};

export const featureFilterData = responseData => {
  /**
   * The Print and Televsion lines need to be hidden at the FE level because of a difference
   * in how they are used on the BE.  Normally for an anonymous user WAG will use the widget's
   * owner to determine what features, etc, to display however for PRINT and BROADCAST the
   * desire is not to show these lines for anonymous users even if the creator of the widget
   * has the features.
   */
  if (isAve(responseData) || isAggregateReadership(responseData)) {
    if (responseData?.[0]?.data?.[0]?.data) {
      const labelIndex = responseData[0].data[0].headers.indexOf('label');
      if (!userHasFeatureFlag(FEATURES.printData)) {
        // remove print line
        responseData[0].data[0].data = responseData[0].data[0].data.filter(
          d => d[labelIndex] !== 'Print',
        );
      }

      if (!userHasFeatureFlag(FEATURES.broadcastData)) {
        // remove broadcast line
        responseData[0].data[0].data = responseData[0].data[0].data.filter(
          d => d[labelIndex] !== 'Television',
        );
      }
    }
  }
  return responseData;
};

export const clearDashboard = () => ({ type: CLEAR_ANALYTIC });
export const clearDuplicateAnalytic = () => ({
  type: CLEAR_DUPLICATE_ANALYTIC,
});

export const addDashboardToCampaign = (
  dashboardId,
  campaigns,
  successMessage,
) => async dispatch => {
  const campaignIdList = campaigns.map(c => c.id);
  try {
    await performPost(`${DASHBOARD_ENDPOINT_V2}/${dashboardId}/campaign`, {
      campaignIdList,
    });
    dispatch(
      addPageMessage({
        text: successMessage(campaigns.map(c => c.title)),
        status: 'success',
        ttl: 6000,
      }),
    );
    dispatch(getCampaignsForUser());
    dispatch(campaignAnalyticsActionCreator());
  } catch (e) {
    dispatch({ type: ADD_DASHBOARD_TO_CAMPAIGNS_ERROR, payload: { error: e } });
    dispatch(
      addPageMessage({
        text: e.response.statusText,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export const setDashboardCsvExportTypeActionCreator = ({
  csvExportType,
}) => dispatch =>
  dispatch({
    type: SET_DASHBOARD_CSV_EXPORT_TYPE,
    payload: { csvExportType: csvExportType },
  });

export const setWidgetsToReload = widgetsToReload => ({
  type: SET_WIDGETS_TO_RELOAD,
  payload: widgetsToReload,
});

export const reloadWidgets = searchId => (dispatch, getState) => {
  const state = getState();
  const widgetsToReload = getWidgetsPoweredBySearch(searchId)(state);
  bustAllWidgetDataCache();
  dispatch(setWidgetsToReload(widgetsToReload || []));
};

export const reloadDashboardWidgets = () => (dispatch, getState) => {
  const state = getState();
  const widgetIds = getDashboardWidgetsIds()(state);
  widgetIds.forEach(id => {
    bustWidgetDataCache(id);
  });
  dispatch(setWidgetsToReload(widgetIds || []));
};

export const deleteWidgetFromReloadList = widgetId => ({
  type: DELETE_WIDGET_FROM_RELOAD_LIST,
  payload: widgetId,
});

export const setDashboardsListSideTrayOpen = value => ({
  type: SET_DASHBOARDS_LISTS_SIDE_TRAY_OPEN,
  payload: value,
});

export const setShouldWidgetUseBackendCache = value => ({
  type: WIDGET_SHOULD_USE_BACKEND_CACHE,
  payload: value,
});

export const clearWidgetsToReload = () => ({ type: CLEAR_WIDGETS_TO_RELOAD });

export default dashboardHubReducer;
