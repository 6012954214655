import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { Body, FormLabel, Media } from '@trendkite/ui';

import WrappedEmailListControl from './WrappedEmailListControl';

const baseClass = 'email-recipients-control';

class EmailRecipientsControl extends Component {
  static baseClass = baseClass;

  state = {
    isFocused: this.props.autoFocus,
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    this.props.onBlur();
  };

  render() {
    const {
      className,
      inputTextDisabled,
      label,
      model,
      min,
      ...otherProps
    } = this.props;
    const { isFocused } = this.state;

    const onFocusEvent = () => {
      if (model !== '.to') return;

      return this.onFocus;
    };

    return (
      <div
        {...otherProps}
        className={`${EmailRecipientsControl.baseClass} ${className}`}
        data-qa="email-recipients-control"
      >
        <Media>
          {label && (
            <Media.Item>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div
                data-qa="8AMEHR0HAuQay8Mgc_JIv"
                className={`${EmailRecipientsControl.baseClass}__label`}
                onClick={onFocusEvent}
                role="button"
                tabIndex="0"
              >
                <FormLabel htmlFor={`${model}`} modifiers={['no-margin']}>
                  {label}
                </FormLabel>
              </div>
            </Media.Item>
          )}
          <Media.Body>
            <Control
              component={WrappedEmailListControl}
              id={`${model}`}
              inputTextDisabled={inputTextDisabled}
              isFocused={isFocused}
              model={`${model}`}
              min={min}
              mapProps={{
                values: props => props.viewValue,
              }}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          </Media.Body>
        </Media>
      </div>
    );
  }
}

EmailRecipientsControl.propTypes = {
  className: PropTypes.string,
  inputTextDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  min: PropTypes.number,
  model: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

EmailRecipientsControl.defaultProps = {
  className: '',
  autoFocus: false,
  label: '',
  min: 0,
  inputTextDisabled: false,
  onBlur: () => {},
};

export const EmailRecipientsPlaceholder = ({ children, className, label }) => {
  return (
    <div className={`${baseClass} ${baseClass}__placeholder ${className}`}>
      <Media>
        {label && (
          <Media.Item>
            <div className={`${baseClass}__label`}>
              <Body color="light">{label}</Body>
            </div>
          </Media.Item>
        )}
        <Media.Body>
          <div className={`${baseClass}__placeholder-content`}>{children}</div>
        </Media.Body>
      </Media>
    </div>
  );
};

EmailRecipientsPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

EmailRecipientsPlaceholder.defaultProps = {
  className: '',
};

export default EmailRecipientsControl;
