export default {
  from: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_FROM_REQUIRED',
    defaultMessage: 'From (Required):',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  fromPlaceholder: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_FROM_REQUIRED_PLACEHOLDER',
    defaultMessage: 'What email address is this message from?',
    description:
      'Used on the email announcement page in the composer as a placeholder for a field.',
  },
  replyTo: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_REPLY_TO_OPTIONAL',
    defaultMessage: 'Reply To (Optional):',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  replyToPlaceholder: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_REPLY_TO_OPTIONAL_PLACEHOLDER',
    defaultMessage: 'What email address should recipients reply to?',
    description:
      'Used on the email announcement page in the composer as a placeholder for a field.',
  },
  senderName: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_SENDER_NAME_OPTIONAL',
    defaultMessage: 'Sender Name (Optional):',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  senderNamePlaceholder: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_SENDER_NAME_OPTIONAL_PLACEHOLDER',
    defaultMessage: 'What is your name?',
    description:
      'Used on the email announcement page in the composer as a placeholder for a field.',
  },
  senderNamePlaceHolderMessageSender: {
    id:
      'EMAIL_CAMPAIGN_COMPOSER_SENDER_NAME_OPTIONAL_PLACEHOLDER_MESSAGE_SENDER',
    defaultMessage: 'What is the name of the message sender?',
    description:
      'Used on the email announcement page in the composer as a placeholder for the sender name field.',
  },
  additionalRecipients: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_ADDITIONAL_RECIPIENTS_OPTIONAL',
    defaultMessage: 'Send Copies To (Optional):',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  additionalRecipientsPlaceholder: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_ADDITIONAL_RECIPIENTS_OPTIONAL_PLACEHOLDER',
    defaultMessage:
      'What email address(es) should we send copies of this message to?',
    description:
      'Used on the email announcement page in the composer as a placeholder for a field.',
  },
  subject: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_SUBJECT_REQUIRED',
    defaultMessage: 'Subject (Required):',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  subjectPlaceholder: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_SUBJECT_REQUIRED_PLACEHOLDER',
    defaultMessage: 'What is the subject of this email message?',
    description:
      'Used on the email announcement page in the composer as a placeholder for a field.',
  },
  body: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_BODY',
    defaultMessage: 'Body',
    description:
      'Used on the email announcement page in the composer as a field.',
  },
  requiredError: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_REQUIRED',
    defaultMessage: 'The "{FIELD_NAME}" field is required.',
    description:
      'Used on the email announcement page in the composer for fields missing data.',
  },
  invalidEmailError: {
    id: 'EMAIL_CAMPAIGN_COMPOSER_INVALID_EMAIL',
    defaultMessage:
      'The "{FIELD_NAME}" field doesn\'t appear to be a valid email address.',
    description:
      'Used on the email announcement page in the composer for fields with invalid email addresses.',
  },
  emailComposerAddAndVerify: {
    id: 'EMAIL_COMPOSER_ADD_AND_VERIFY',
    defaultMessage: 'Add and verify a new email address',
    description: 'Used to add a new verified email address',
  },
  emailComposerPendingVerification: {
    id: 'EMAIL_COMPOSER_PENDING_VERIFICATION',
    defaultMessage: 'Pending Verification',
    description:
      'Default text to reference a prending verification status for a user email',
  },
  emailComposerPendingVerificationDKIM: {
    id: 'EMAIL_COMPOSER_PENDING_VERIFICATION_DKIM',
    defaultMessage:
      'Pending Verification. Please check your inbox for a verification email.',
    description:
      'Default text to reference a prending verification status for a user email',
  },
  emailComposerPendingDomainAuthentication: {
    id: 'EMAIL_COMPOSER_PENDING_DKIM_Authentication',
    defaultMessage:
      'Pending DKIM Authentication. Please see the Email Settings page for more details.',
    description:
      'Default text to reference a pending DKIM Authentication status for a user email',
  },
};
