export default {
  columnMultiMetric: {
    id: 'COLUMN_MULTI_METRIC',
    description:
      'Label name for wiget table column displaying multiple metrics',
    defaultMessage: 'Total',
  },
  columnLeftMultiMetric: {
    id: 'COLUMN_LEFT_MULTI_METRIC',
    description:
      'Full label name for widget table left column displaying multiple metrics',
    defaultMessage: 'Metric',
  },
  columnSentimentLong: {
    id: 'COLUMN_SENTIMENT_LONG',
    description:
      'Full label name for wiget table column displaying sentiment metric',
    defaultMessage: 'Percentage',
  },
  columnSentimentShort: {
    id: 'COLUMN_SENTIMENT_SHORT',
    description:
      'Short label name for widget table column displaying sentiment metric',
    defaultMessage: '%',
  },
  columnArticleMentionLong: {
    id: 'COLUMN_ARTICLE_MENTION_LONG',
    description:
      'Full label name for widget table column displaying article mention metric',
    defaultMessage: 'Total Mentions',
  },
  columnArticleMentionShort: {
    id: 'COLUMN_ARTICLE_MENTION_SHORT',
    description:
      'Short label name for widget table column displaying article mention metric',
    defaultMessage: 'Mentions',
  },
  columnTotalReadershipLong: {
    id: 'COLUMN_TOTAL_READERSHIP_AND_VIEWERSHIP_LONG_',
    description:
      'Full label name for widget table column displaying total readership & viewership metric',
    defaultMessage: 'Total Readership & Viewership',
  },
  columnTotalReadershipShort: {
    id: 'COLUMN_TOTAL_READERSHIP_SHORT_',
    description:
      'Short label name for widget table column displaying total readership metric',
    defaultMessage: 'Total Readership',
  },
  columnMobileReadershipLong: {
    id: 'COLUMN_MOBILE_READERSHIP_LONG',
    description:
      'Full label name for widget table column displaying mobile readership metric',
    defaultMessage: 'Mobile Readership',
  },
  columnMobileReadershipShort: {
    id: 'COLUMN_MOBILE_READERSHIP_SHORT',
    description:
      'Short label name for widget table column displaying mobile readership metric',
    defaultMessage: 'Mobile Readership',
  },
  columnDesktopReadershipLong: {
    id: 'COLUMN_DESKTOP_READERSHIP_LONG',
    description:
      'Full label name for widget table column displaying desktop readership metric',
    defaultMessage: 'Desktop Readership',
  },
  columnDesktopReadershipShort: {
    id: 'COLUMN_DESKTOP_READERSHIP_SHORT',
    description:
      'Short label name for widget table column displaying desktop readership metric',
    defaultMessage: 'Desktop Readership',
  },
  columnTotalSessionsLong: {
    id: 'COLUMN_TOTAL_SESSIONS_LONG',
    description:
      'Full label name for widget table column displaying total sessions metric',
    defaultMessage: 'Total Sessions',
  },
  columnSiteSessionShort: {
    id: 'COLUMN_SITE_SESSION_SHORT',
    description:
      'Short label name for widget table column displaying site session metric',
    defaultMessage: 'GA Analytics',
  },
  columnUniquePrArticlesLong: {
    id: 'COLUMN_UNIQUE_PR_ARTICLES_LONG',
    description:
      'Full label name for widget table column displaying unique PR articles metric',
    defaultMessage: 'Unique PR Articles',
  },
  columnUniquePrArticlesShort: {
    id: 'COLUMN_UNIQUE_PR_ARTICLES_SHORT',
    description:
      'Short label name for widget table column displaying unique PR articles metric',
    defaultMessage: 'Articles',
  },
  columnPrReferralTrafficShort: {
    id: 'COLUMN_PR_REFERRAL_TRAFFIC_SHORT',
    description:
      'Short label name for widget table column displaying referral traffic metric',
    defaultMessage: 'Traffic',
  },
  columnSocialAmpLong: {
    id: 'COLUMN_SOCIAL_AMP_LONG',
    description:
      'Full label name for widget table column displaying social amplification metric',
    defaultMessage: 'Activity',
  },
  columnSocialAmpShort: {
    id: 'COLUMN_SOCIAL_AMP_SHORT',
    description:
      'Short label name for widget table column displaying social amplification metric',
    defaultMessage: 'Activity',
  },
  columnAdEquivalencyLong: {
    id: 'COLUMN_AVE_LONG',
    description:
      'Full label name for widget table column displaying ad equivalency metric',
    defaultMessage: 'Ad Equivalency',
  },
  columnAdEquivalencyShort: {
    id: 'COLUMN_AVE_SHORT',
    description:
      'Short label name for widget table column displaying ad equivalency metric',
    defaultMessage: 'Ad Equiv',
  },
  columnAvgArticleImpactLong: {
    id: 'COLUMN_AVG_ARTICLE_IMPACT_LONG',
    description:
      'Full label name for widget table column displaying average article impact metric',
    defaultMessage: 'Article Impact',
  },
  columnAvgArticleImpactShort: {
    id: 'COLUMN_AVG_ARTICLE_IMPACT_SHORT',
    description:
      'Short label name for widget table column displaying average article impact metric',
    defaultMessage: 'Impact',
  },
  columnDailyUniqueViews: {
    id: 'COLUMN_DAILY_UNIQUE_VIEWS',
    description:
      'Label name for widget table column displaying verified unique viewers metric',
    defaultMessage: 'Daily Unique Viewers',
  },
  columnLeftSentiment: {
    id: 'COLUMN_LEFT_SENTIMENT',
    description:
      'Full label name for widget table left column displaying sentiment metric',
    defaultMessage: 'Sentiment',
  },
  columnLeftPublication: {
    id: 'COLUMN_LEFT_PUBLICATION',
    description:
      'Full label name for widget table left column displaying publication metric',
    defaultMessage: 'Publisher',
  },
  columnLeftKeyMessage: {
    id: 'COLUMN_LEFT_KEY_MESSAGE',
    description:
      'Full label name for widget table left column displaying key messages metric',
    defaultMessage: 'Key Message',
  },
  columnLeftSocialPlatform: {
    id: 'COLUMN_LEFT_SOCIAL_PLATFORM',
    description:
      'Full label name for widget table left column displaying social platform metric',
    defaultMessage: 'Social Network',
  },
  columnLeftAggregateReadership: {
    id: 'COLUMN_LEFT_AGGREGATE_READERSHIP',
    description:
      'Full label name for widget table left column displaying aggregate readership metric',
    defaultMessage: 'Media Type',
  },
  columnLeftDefault: {
    id: 'COLUMN_LEFT_DEFAULT',
    description:
      'Full label name for widget table left column displaying default column metric',
    defaultMessage: 'Search Name',
  },
  columnLeftSEOImpact: {
    id: 'COLUMN_LEFT_SEO_IMPACT',
    description:
      'Full label name for widget table left column displaying domain authority sections',
    defaultMessage: 'Domain Authority',
  },
  columnLeftInternational: {
    id: 'COLUMN_LEFT_INTERNATIONAL',
    description:
      'Fill label name for widget table left column displaying country names',
    defaultMessage: 'Country',
  },
  columnLeftState: {
    id: 'COLUMN_LEFT_STATE',
    description:
      'Fill label name for widget table left column displaying state names',
    defaultMessage: 'State',
  },
  columnLeftProvince: {
    id: 'COLUMN_LEFT_PROVINCE',
    description:
      'Fill label name for widget table left column displaying province names',
    defaultMessage: 'Province',
  },
  columnLeftCity: {
    id: 'COLUMN_LEFT_CITY',
    description:
      'Fill label name for widget table left column displaying city names',
    defaultMessage: 'City',
  },
  columnViews: {
    id: 'COLUMN_VIEWS',
    description: 'Label name for widget table column displaying views metric',
    defaultMessage: 'Views',
  },
  columnPostsLong: {
    id: 'COLUMN_POST_LONG',
    description:
      'Long label name for widget table column displaying posts metric',
    defaultMessage: 'Total Posts',
  },
  columnPostsShort: {
    id: 'COLUMN_POST_SHORT',
    description:
      'Short label name for widget table column displaying posts metric',
    defaultMessage: 'Posts',
  },
  columnConversions: {
    id: 'IMPACT_COLUMN_CONVERSIONS',
    description:
      'Label name for widget table column displaying trending conversions metric',
    defaultMessage: 'Impact Total Conversions',
  },
};
